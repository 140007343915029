import React from "react";
// import { languageList } from "../../constants";

const Selector = (props) => {
  const { value, setValue, options } = props;
  const list = options.filter((e) => e !== value);
  list.unshift(value);
  return (
    <div>
      <select
        className="form-select"
        onChange={(e) => {
          setValue(e.target.value);
        }}
      >
        {list.map((lg) => {
          if (lg === value) {
            return (
              <option defaultValue value={lg} key={lg}>
                {lg}
              </option>
            );
          }
          return (
            <option key={lg} value={lg}>
              {lg}
            </option>
          );
        })}
      </select>
    </div>
  );
};

export default Selector;
