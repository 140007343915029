import React from "react";

export default function GridItem(props) {
  const { message = "", onClickActions = () => {}, isNew = false } = props;

  return (
    <div className="grid-item">
      {isNew && (
        <div>
          <div className="circle" />
          {/* <span> This is new</span> */}
        </div>
      )}
      <div className="grid-item-title">
        <span>{message}</span>
      </div>

      <button
        className="grid-item-btn"
        color="primary"
        onClick={onClickActions}
      >
        {"Try it!!"}
      </button>
    </div>
  );
}
