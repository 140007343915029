import React, { useState, useCallback, useEffect } from "react";
import TextArea from "./text-area";
import TextDisplay from "./text-display";
import Selector from "./selector";
import { languageList } from "../../constants";
import QRCode from "react-qr-code";
import { useParams } from "react-router-dom";
import { initializeApp } from "firebase/app";
import config from "../../config";
import { ref, getDatabase, update, onValue } from "firebase/database";
import { useObject } from "react-firebase-hooks/database";

const firebaseApp = initializeApp(config);
const database = getDatabase(firebaseApp);

export default function ShareBoard() {
  const { documentID } = useParams("documentID");
  const dbref = ref(database, "shareboards/" + documentID);
  const [snapshots, loading, error] = useObject(
    ref(database, "shareboards/" + documentID)
  );

  const [value, setValue] = useState("");
  const [preview, setPreview] = useState(false);
  const [language, setLanguage] = useState("java");
  var textOnChange = (value) => {
    setValue(value);
    const data = update(dbref, {
      value: value,
      timestamp: Date.now(),
    });
  };

  const [refreshtime, setRefreshtime] = useState(Date.now());

  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     setrefreshtime(Date.now());
  //   }, 2000);

  //   return () => clearInterval(intervalId);
  // }, []);

  onValue(
    dbref,
    (snapshot) => {
      const data = snapshot.val();
      const { value: val = "" } = data;
      if (val !== value && refreshtime + 2000 < Date.now()) {
        setValue(val);
        setRefreshtime(Date.now());
      }
    },
    (error) => console.log(error)
  );

  useEffect(() => {
    if (!error && !loading) {
      setValue(snapshots._node.children_.root_.value.value_);
    }
  }, [error, loading]);

  return (
    <div>
      {error && <strong>Error: {error}</strong>}
      {loading && <span>Loading...</span>}
      {!loading && snapshots && (
        <React.Fragment>
          <div>Welcome to white board</div>
          <div>
            <div>
              <Selector
                value={language}
                setValue={setLanguage}
                options={languageList}
              />
              {preview ? (
                <TextDisplay value={value} language={language} />
              ) : (
                <TextArea
                  value={value}
                  setValue={textOnChange}
                  language={language}
                />
              )}
              <button color="primary" onClick={() => setPreview(!preview)}>
                {!preview ? "Preview" : "Edit"}
              </button>
              <button
                onClick={() => {
                  textOnChange(JSON.stringify(JSON.parse(value), null, "\t"));
                }}
              >
                Formate JSON
              </button>
              <button
                color="primary"
                onClick={() => {
                  navigator.clipboard.writeText(value);
                }}
              >
                {"Copy"}
              </button>
            </div>
            <div style={{ background: "white", padding: "16px" }}>
              <div>
                Share this to give other access to this page, noted: anything
                share here is accessable by anyone
              </div>
              <QRCode title="Share this page" value={window.location.href} />
            </div>
          </div>
        </React.Fragment>
      )}
    </div>
  );
}
