import React, { useState } from "react";
import { CopyBlock, dracula } from "react-code-blocks";

const TextDisplay = (props) => {
  const {
    value,
    language = "javascript",
    showLineNumbers = 100,
    startingLineNumber = 1,
  } = props;
  return (
    <div className="code-edit">
      <CopyBlock
        text={value}
        language={language}
        showLineNumbers={showLineNumbers}
        startingLineNumber={startingLineNumber}
        theme={dracula}
        wrapLines
      />
    </div>
  );
};

export default TextDisplay;
