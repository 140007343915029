import React, { useState } from "react";

export default function TipsCalculator() {
  const [total, setTotal] = useState(0);

  const tipsRates = [0.1, 0.12, 0.15, 0.18, 0.2, 0.25];

  return (
    <div className="tips-container">
      <label>Total:</label>
      <input type={"number"} onChange={(e) => setTotal(e.target.value)} />
      <br />
      {tipsRates.map((t) => {
        return (
          <div key={t} className="tip-items">
            <label>{t * 100}%:</label>
            <br />
            <label>
              Tip is ${(total * t).toFixed(2)} = ${(total * (1 + t)).toFixed(2)}
            </label>
            <br />
          </div>
        );
      })}
    </div>
  );
}
