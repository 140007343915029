import React, { useState } from "react";
import { useList } from "react-firebase-hooks/database";
import { initializeApp } from "firebase/app";
import config from "../../config";
import { ref, getDatabase, set, push, update } from "firebase/database";
import { DB_PATH_IDEAS_LIST, getUniqueId } from "../../constants";
const firebaseApp = initializeApp(config);
const database = getDatabase(firebaseApp);

export default function IdeasList(props) {
  const dbref = ref(database, DB_PATH_IDEAS_LIST);
  const [snapshots, loading, error] = useList(dbref);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  function onSubmit() {
    if (!title || !description) {
      console.log("title or description is missing");
      return;
    }
    console.log({ title, description });
    var computerId = getUniqueId();
    const data = push(dbref, {
      title: title,
      description: description,
      ower: computerId,
      likeList: [computerId],
      timestamp: Date.now(),
      vote: 1,
    });
  }

  //   snapshots.map((v) => console.log({ key: v.key, val: v.val() }));
  const heading = ["Title", "Descirition", "number of Likes"];
  return (
    <div>
      {error && <strong>Error: {error}</strong>}
      {loading && <span>List: Loading...</span>}
      {!loading && snapshots && (
        <React.Fragment>
          <table className="table">
            <thead>
              <tr key="header">
                {heading.map((head) => (
                  <th key={head}>{head}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {snapshots.map((v, i) => {
                const key = v.key;
                const { description, title, likeList = [] } = v.val();
                const vote = likeList.length;
                function updateLikes() {
                  //   console.log("updateing " + key);
                  const dbRefItem = ref(
                    database,
                    DB_PATH_IDEAS_LIST + "/" + key
                  );
                  var newList = likeList;
                  const uid = getUniqueId();
                  if (newList.includes(uid)) {
                    newList = newList.filter((e) => e !== uid);
                  } else {
                    newList.push(uid);
                  }
                  update(dbRefItem, {
                    likeList: newList,
                  });
                }
                return (
                  <tr id={key} key={i}>
                    <td>{title}</td>
                    <td>{description}</td>
                    <td>
                      <label style={{ marginRight: "10px" }}>
                        {"Like Count: " + vote}
                      </label>
                      <button onClick={() => updateLikes()}>
                        {likeList.includes(getUniqueId()) ? "Dislike" : "Like"}
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </React.Fragment>
      )}
      <form>
        <div style={{ margin: "10px" }}>
          <label style={{ marginRight: "10px" }}>Title</label>
          <input type={"text"} onChange={(e) => setTitle(e.target.value)} />
        </div>
        <div style={{ margin: "10px" }}>
          <label style={{ marginRight: "10px" }}>Description</label>
          <textarea onChange={(e) => setDescription(e.target.value)} />
        </div>
        <button
          onClick={(e) => {
            e.preventDefault();
            onSubmit();
          }}
        >
          {" "}
          Submit{" "}
        </button>
      </form>
    </div>
  );
}
