import React, { useEffect, useState } from "react";

import Confetti from "react-confetti";
import { useParams } from "react-router-dom";
import { initializeApp } from "firebase/app";
import config from "../../config";
import { ref, getDatabase, update, onValue } from "firebase/database";
import { useObject } from "react-firebase-hooks/database";
import { COUNTDOWN_DB } from "../../constants";
import ShareThisPageWidget from "./shareThisPageWidget";

const firebaseApp = initializeApp(config);
const database = getDatabase(firebaseApp);

export default function CountDownComponent() {
  const { documentID } = useParams("documentID");
  const dbref = ref(database, COUNTDOWN_DB + "/" + documentID);

  const [submitted, setSubmitted] = useState(false);
  const [snapshot, loading, error] = useObject(dbref);
  const [value, setValue] = useState("");
  useEffect(() => {
    if (!error && !loading) {
      const data = snapshot.val();
      if (data !== null) {
        console.log({ snapshot, data });
        const { date } = data;
        setValue(date);
        setSubmitted(true);
      }
    }
  }, [error, loading]);

  const updateDateHandler = (e) => {
    setValue(e.target.value);
  };
  const onSubmit = () => {
    const data = update(dbref, {
      date: value,
      timestamp: Date.now(),
    });
    setSubmitted(true);
  };
  const diffTime = new Date(value) - Date.now();
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  // const diffHours = diffTime - diffDays * 24
  console.log(diffTime + " milliseconds");
  console.log(diffDays + " days");
  return (
    <div className="countdown-page">
      {!value || value === null || !submitted ? (
        <div>
          {" "}
          <label>Target Date:</label>
          <input
            type="date"
            id="start"
            name="trip-start"
            value={value}
            min="1900-01-01"
            max="4000-12-31"
            onChange={updateDateHandler}
          />{" "}
          <button onClick={onSubmit}>Submit</button>
        </div>
      ) : (
        <div>
          <Confetti /> <label>Target Time:</label>
          {value}
          <br />
          <label>
            There is{" "}
            {diffDays == 1 ? diffDays + " more day" : diffDays + " more days"}{" "}
            to go
          </label>{" "}
          <ShareThisPageWidget />
        </div>
      )}
    </div>
  );
}
