import React, { useState } from "react";
import QRCode from "react-qr-code";
import TextArea from "./text-area";

export default function QRCodeGenrator() {
  const [value, setValue] = useState("");
  const [display, toggleDisplay] = useState(true);

  return (
    <div>
      {display ? (
        <TextArea value={value} setValue={setValue} language="plaintext" />
      ) : (
        <div>
          <QRCode title="QR code" value={value} />
        </div>
      )}
      <button onClick={() => toggleDisplay(!display)}>
        {" "}
        {display ? "Generate" : "Go back"}{" "}
      </button>
    </div>
  );
}
