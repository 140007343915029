import React from "react";
import { Link, useParams } from "react-router-dom";
import { getUserData } from "../../utils/firebase-utils";
import { useEffect, useState } from "react";
import { isValidUrl } from "../../utils";
import { PERSONAL_PROFILE_PAGE } from "../../constants";

export default function ContactCard() {
  const { userID } = useParams("userID");
  const [userData, setUserData] = useState({});
  const { data: { email, name, profile_url, userPlatforms = [] } = {} } =
    userData;
  userPlatforms.push({ platform: "Email", link: email });
  useEffect(() => {
    const unsubscribe = getUserData(
      userID,
      (querySnapshot) => {
        const data = querySnapshot.docs.map((docSnapshot) => {
          return { data: docSnapshot.data(), docId: docSnapshot.id };
        });
        setUserData(data[0]);
      },
      (error) => {
        console.log(error);
      }
    );
    return unsubscribe;
  }, []);

  return (
    <div>
      <div className="profile-picture">
        {profile_url ? (
          <img
            src={profile_url}
            className="profile-img"
            alt="profile picture"
          />
        ) : (
          <span>This user did not upload any picture</span>
        )}
      </div>
      <h2>{name}</h2>
      <div className="social-media-list">
        <h3>Contacts: </h3>
        {userPlatforms.map((item) => {
          const { platform, link } = item;
          return (
            <div key={platform} className="social-media-list-item">
              <div className="social-media-list-item-cell">{platform}: </div>{" "}
              {isValidUrl(link) ? (
                <div className="social-media-list-item-cell">
                  {" "}
                  <a href={link} target="_blank">
                    {link}{" "}
                  </a>
                </div>
              ) : (
                <div className="social-media-list-item-cell">{link}</div>
              )}
              <button
                color="primary"
                onClick={() => {
                  navigator.clipboard.writeText(link);
                }}
              >
                Copy
              </button>
            </div>
          );
        })}
      </div>
      <div>
        <Link to={PERSONAL_PROFILE_PAGE}>Create your Own</Link>
      </div>
    </div>
  );
}
