import React, { useState } from "react";
import {
  IDEAS_LIST_PATH,
  QR_CODE_PATH,
  WHITE_BOARD_PATH,
  COUNTDOWN_DB,
  COUNT_DOWN_PATH,
  SLIDE_TEXT,
  TIPS_CALCULATOR,
  PERSONAL_PROFILE_PAGE,
} from "../constants";
import { useNavigate } from "react-router-dom";
import config from "../config";
import { ref, getDatabase, set, push } from "firebase/database";
import { useList } from "react-firebase-hooks/database";
import { initializeApp } from "firebase/app";
import GridItem from "./widgets/grid-item";

const firebaseApp = initializeApp(config);
const database = getDatabase(firebaseApp);

export default function Body() {
  const [value, setValue] = useState("");
  const [preview, setPreview] = useState(false);
  const [language, setLanguage] = useState("java");
  let navigate = useNavigate();
  const dbref = ref(database, "shareboards");

  function createNewPage() {
    const data = push(dbref, {
      value: value,
      timestamp: Date.now(),
    });
    const {
      _path: { pieces_ = [] },
    } = data;
    if (pieces_.length != 2) {
      console.error("could not find this record");
      return;
    }
    const id = pieces_[1];
    const path = `${WHITE_BOARD_PATH}/` + id;
    navigate(path);
  }

  function createNewCountDown() {
    const cdref = ref(database, COUNTDOWN_DB);
    const data = push(cdref, {
      value: value,
      timestamp: Date.now(),
    });
    const {
      _path: { pieces_ = [] },
    } = data;
    if (pieces_.length != 2) {
      console.error("could not find this record");
      return;
    }
    const id = pieces_[1];
    const path = `${COUNT_DOWN_PATH}/` + id;
    navigate(path);
  }
  function navigateToQRCodePage() {
    navigate(QR_CODE_PATH);
  }
  function navigateTo(path) {
    navigate(path);
  }

  return (
    <div className="body-container">
      <div>Welcome to Quick tools</div>
      <div className="grid-container">
        <GridItem
          message="Share contents between friends or other devices"
          onClickActions={createNewPage}
        />
        <GridItem
          message="Create a Sliding text"
          onClickActions={() => navigateTo(SLIDE_TEXT)}
        />
        <GridItem
          message="Calculate Tips"
          onClickActions={() => navigateTo(TIPS_CALCULATOR)}
        />
        <GridItem
          message="Create/Edit your own contact card, put down your facebook, instagram, twitter, or wechat and share with your friends"
          onClickActions={() => navigateTo(PERSONAL_PROFILE_PAGE)}
          isNew={true}
        />
        <GridItem
          message="Create a new count down"
          onClickActions={createNewCountDown}
        />
        <GridItem
          message="Genrate QR Code"
          onClickActions={navigateToQRCodePage}
        />
        <GridItem
          message="If you have any other new Ideas, suggest it here!!"
          onClickActions={() => navigateTo(IDEAS_LIST_PATH)}
        />
      </div>
    </div>
  );
}
