import React, { Component, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { LOGIN_PAGE_PATH } from "../constants";
import { useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, logout } from "./../utils/firebase-utils";

function Header() {
  const navigrate = useNavigate();
  const [isUserLogin, setIsUserLogin] = useState(false);

  const [user, loading, error] = useAuthState(auth);
  useEffect(() => {
    if (loading) return;
    if (user) {
      setIsUserLogin(true);
    } else {
      setIsUserLogin(false);
    }
  }, [user, loading]);

  return (
    <header className="header">
      <nav className="navbar navbar-expand-md navbar-dark bg-dark">
        <div>
          <a href="/" className="navbar-brand">
            Quick Tools
          </a>
        </div>
        <ul className="navbar-nav">
          {
            <li>
              <Link className="nav-link" to="/">
                Home
              </Link>
            </li>
          }
        </ul>
        <ul className="navbar-nav navbar-collapse justify-content-end">
          {
            <li>
              <div
                className="nav-link"
                onClick={() => {
                  if (!isUserLogin) {
                    navigrate(LOGIN_PAGE_PATH);
                  } else {
                    logout();
                  }
                }}
              >
                {loading ? "Loading" : !isUserLogin ? "Login" : "Logout"}
              </div>
            </li>
          }
        </ul>
      </nav>
    </header>
  );
}

export default Header;
