import ShareBoard from "../components/widgets/share_board";
import QRCodeGenrator from "../components/widgets/qrcode-generator";
import SlideText from "../components/widgets/slide-text";
import CountDownComponent from "../components/widgets/countdown";
import IdeasList from "../components/widgets/ideas-list";
import TipsCalculator from "../components/widgets/tips-calculator";
import LoginPage from "../components/widgets/login-page";
import PersonalProfilePage from "../components/widgets/personal-profile-page";
import ContactCard from "../components/widgets/contact-card";
// import config from "../config";
// import { ref, getDatabase, set, push } from "firebase/database";
// const firebaseApp = initializeApp(config);
// const database = getDatabase(firebaseApp);

export const languageList = [
  "abap",
  "aes",
  "apex",
  "azcli",
  "bat",
  "brainfuck",
  "c",
  "cameligo",
  "clike",
  "clojure",
  "coffeescript",
  "cpp",
  "csharp",
  "csp",
  "css",
  "dart",
  "dockerfile",
  "erlang",
  "fsharp",
  "go",
  "graphql",
  "handlebars",
  "hcl",
  "html",
  "ini",
  "java",
  "javascript",
  "json",
  "jsx",
  "julia",
  "kotlin",
  "less",
  "lex",
  "livescript",
  "lua",
  "markdown",
  "mips",
  "msdax",
  "mysql",
  "nginx",
  "objective-c",
  "pascal",
  "pascaligo",
  "perl",
  "pgsql",
  "php",
  "plaintext",
  "postiats",
  "powerquery",
  "powershell",
  "pug",
  "python",
  "r",
  "razor",
  "redis",
  "redshift",
  "restructuredtext",
  "ruby",
  "rust",
  "sb",
  "scala",
  "scheme",
  "scss",
  "shell",
  "sol",
  "sql",
  "st",
  "stylus",
  "swift",
  "systemverilog",
  "tcl",
  "toml",
  "tsx",
  "twig",
  "typescript",
  "vb",
  "vbscript",
  "verilog",
  "vue",
  "xml",
  "yaml",
];

export const QR_CODE_PATH = "/genreate-qrcode";
export const WHITE_BOARD_PATH = "/white-board-ui";
export const IDEAS_LIST_PATH = "/new-ideas";
export const COUNT_DOWN_PATH = "/countdown";
export const SLIDE_TEXT = "/slide-text";
export const TIPS_CALCULATOR = "/tips-calculator";
export const LOGIN_PAGE_PATH = "/login";
export const PERSONAL_PROFILE_PAGE = "/personal-profile";
export const CONTACT_CARD_PATH = "/personal-profile/:userID";

export const COUNTDOWN_DB = "countdown_DB";

export const UNIQUE_ID = "UNIQUE_ID";
export const DB_PATH_IDEAS_LIST = "ideas-list";

export function generateRandomID() {
  const length = 20;
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export function getUniqueId() {
  var computerId = localStorage.getItem(UNIQUE_ID);
  if (!computerId) {
    const ranId = generateRandomID();
    localStorage.setItem(UNIQUE_ID, ranId);
    return ranId;
  }
  return computerId;
}

export const gridItemsList = [
  {
    path: WHITE_BOARD_PATH + "/:documentID",
    element: <ShareBoard />,
    message: "Share contents between friends or other devices",
  },
  {
    path: QR_CODE_PATH,
    element: <QRCodeGenrator />,
    message: "Genrate QR Code",
  },
  {
    path: SLIDE_TEXT,
    element: <SlideText />,
    message: "Create a Sliding text",
  },
  {
    path: TIPS_CALCULATOR,
    element: <TipsCalculator />,
    message: "Calculate Tips",
  },
  {
    path: COUNT_DOWN_PATH + "/:documentID",
    element: <CountDownComponent />,
    message: "Create a new count down",
  },
  {
    path: IDEAS_LIST_PATH,
    element: <IdeasList />,
    message: "If you have any other new Ideas, suggest it here!!",
  },
  {
    path: LOGIN_PAGE_PATH,
    element: <LoginPage />,
    message: "",
  },
  { path: PERSONAL_PROFILE_PAGE, element: <PersonalProfilePage /> },
  { path: CONTACT_CARD_PATH, element: <ContactCard /> },
];
