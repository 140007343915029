const config = {
  apiKey: "AIzaSyCv7Si08_WqrfNINacOSUirbBX42tjp_Y0",
  authDomain: "whiteboard-6caa1.firebaseapp.com",
  databaseURL: "https://whiteboard-6caa1-default-rtdb.firebaseio.com",
  projectId: "whiteboard-6caa1",
  storageBucket: "whiteboard-6caa1.appspot.com",
  messagingSenderId: "7403157428",
  appId: "1:7403157428:web:fe33e536f4365c09c0a4e1",
  measurementId: "G-6X3QXN6HCG",
};

export default config;
