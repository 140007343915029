import React, { useState } from "react";
import QRCode from "react-qr-code";

export default function ShareThisPageWidget({ value = window.location.href }) {
  const [showQRCode, setShowQRCode] = useState(false);
  return (
    <div>
      {showQRCode && (
        <div style={{ background: "white", padding: "16px" }}>
          <div>Share This Page by QRCode</div>
          <QRCode title="Share this page" value={value} />
        </div>
      )}
      <button onClick={() => setShowQRCode(!showQRCode)}>
        {" "}
        {!showQRCode ? "Share by " : "Hide"} QR code
      </button>
    </div>
  );
}
