import React, { useState, useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import {
  auth,
  storage,
  getUserData,
  updateUserData,
} from "./../../utils/firebase-utils";
import { Link } from "react-router-dom";
import { LOGIN_PAGE_PATH } from "../../constants";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { isValidUrl } from "../../utils";
import ShareThisPageWidget from "./shareThisPageWidget";
export default function PersonalProfilePage() {
  const [isUserLogin, setIsUserLogin] = useState(false);
  const [userData, setUserData] = useState({});
  const [platform, setPlatform] = useState("");
  const [link, setLink] = useState("");
  const [percent, setPercent] = useState(0);
  const [file, setFile] = useState("");
  const [user, loading, error] = useAuthState(auth);
  function handleChange(event) {
    setFile(event.target.files[0]);
  }

  const handleUpload = () => {
    if (!file) {
      alert("Please upload an image first!");
    }
    const storageRef = ref(
      storage,
      `/user-files/${user.uid}/profile-pictures/${file.name}`
    );
    const uploadTask = uploadBytesResumable(storageRef, file);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const percent = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setPercent(percent);
      },
      (err) => console.log(err),
      () => {
        // download url
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          const { docId, data } = userData;
          const newData = { ...data, profile_url: url };
          updateUserData(docId, newData);
        });
      }
    );
  };

  const addMorePlatform = () => {
    if (platform && link) {
      const {
        docId,
        data,
        data: { userPlatforms = [] },
      } = userData;
      userPlatforms.push({ platform, link });
      const newData = { ...data, userPlatforms };
      updateUserData(docId, newData);
      setPlatform("");
      setLink("");
    }
  };

  useEffect(() => {
    if (loading) return;
    if (user) {
      setIsUserLogin(true);
    } else {
      setIsUserLogin(false);
    }
  }, [user, loading]);
  useEffect(() => {
    if (isUserLogin) {
      const unsubscribe = getUserData(
        user.uid,
        (querySnapshot) => {
          const data = querySnapshot.docs.map((docSnapshot) => {
            return { data: docSnapshot.data(), docId: docSnapshot.id };
          });
          setUserData(data[0]);
        },
        (error) => {
          console.log(error);
        }
      );
      return unsubscribe;
    }
  }, [isUserLogin]);

  if (!isUserLogin) {
    return (
      <div>
        <h3> Please login first before creating a profile</h3>
        <Link to={LOGIN_PAGE_PATH}> Login </Link>
      </div>
    );
  }

  const { docId, data: { name = "", userPlatforms = [], profile_url } = {} } =
    userData;
  return (
    <div className="body-container">
      <h2>Welcome {name}</h2>
      <div className="profile-picture">
        {profile_url ? (
          <img
            src={profile_url}
            className="profile-img"
            alt="profile picture"
          />
        ) : (
          <span>upload a profile Picture</span>
        )}
        <input
          type="file"
          onChange={handleChange}
          accept=""
          placeholder="upload another profile"
        />
        {file && (
          <button onClick={handleUpload}>Upload as new Profile Picture</button>
        )}
        {percent !== 0 && <p>{percent} "% done"</p>}
      </div>
      <div className="social-media-list">
        <h3>Contacts: </h3>
        {userPlatforms.map((item) => {
          const { platform, link } = item;
          return (
            <div key={platform} className="social-media-list-item">
              <div className="social-media-list-item-cell">{platform}: </div>{" "}
              {isValidUrl(link) ? (
                <div className="social-media-list-item-cell">
                  {" "}
                  <a href={link} target="_blank">
                    {link}{" "}
                  </a>
                </div>
              ) : (
                <div className="social-media-list-item-cell">{link}</div>
              )}
              <button
                color="primary"
                onClick={() => {
                  navigator.clipboard.writeText(link);
                }}
              >
                Copy
              </button>
            </div>
          );
        })}
      </div>
      <label>enter the platform: </label>
      <input
        value={platform}
        onChange={(e) => {
          setPlatform(e.target.value);
        }}
      />
      <br />
      <label>enter the link to share:</label>
      <input
        value={link}
        onChange={(e) => {
          setLink(e.target.value);
        }}
      />
      <br />
      <button onClick={addMorePlatform}>Submit</button>
      <ShareThisPageWidget value={window.location.href + "/" + user.uid} />
    </div>
  );
}
