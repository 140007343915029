import React, { useState } from "react";
import TextArea from "./text-area";

function isChineseChar(char) {
  return char.match(/[\u3400-\u9FBF]/);
}

export default function SlideText() {
  const [value, setValue] = useState("");
  const arr = [];
  const values = value.split(" ");
  values.forEach((v) => {
    if (isChineseChar(v.charAt(0))) {
      v.split("").forEach((i) => {
        arr.push(i);
      });
    } else {
      arr.push(v);
    }
  });
  arr.push("");
  arr.push("");
  const [index, setIndex] = useState(0);
  const [showText, setShowText] = useState(arr[index]);
  const [editing, setEditing] = useState(true);

  if (!editing) {
    if (index === arr.length) {
      setIndex(0);
    }
    setTimeout(() => {
      setShowText(arr[index]);
      setIndex(index + 1);
    }, 700);
  }
  return (
    <div>
      {editing ? (
        <TextArea value={value} setValue={setValue} language={"plaintext"} />
      ) : (
        <div>
          <div
            className="slide-text"
            style={{ fontSize: 250 - showText.length * 15 + "px" }}
          >
            {showText}
          </div>
          <h1>{value}</h1>
        </div>
      )}
      <button
        className="toggle-btn"
        onClick={() => {
          setEditing(!editing);
          setIndex(0);
        }}
      >
        toggle
      </button>
    </div>
  );
}
