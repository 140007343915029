import React, { useState } from "react";
import ErrorPage from "./components/widgets/error-page";
import Header from "./components/header";
import Footer from "./components/footer";
import ShareBoard from "./components/widgets/share_board";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { IntlProvider } from "react-intl";
import Body from "./components/body";
import "bootstrap/dist/css/bootstrap.min.css";
import { initializeApp } from "firebase/app";

import config from "./config";
import QRCodeGenrator from "./components/widgets/qrcode-generator";
import {
  QR_CODE_PATH,
  WHITE_BOARD_PATH,
  IDEAS_LIST_PATH,
  COUNT_DOWN_PATH,
  SLIDE_TEXT,
  gridItemsList,
} from "./constants";
import IdeasList from "./components/widgets/ideas-list";
import CountDownComponent from "./components/widgets/countdown";
import SlideText from "./components/widgets/slide-text";

class App extends React.Component {
  constructor(props) {
    super(props);
    initializeApp(config);

    // this.state = {
    //   developers: []
    // }
  }

  render() {
    const props = this.props;
    return (
      <div className="App">
        <IntlProvider locale="en">
          <Router>
            <>
              <Header />
              {/* <Body {...props}  /> */}
              <div className="container">
                <Routes>
                  <Route path="/" exact element={<Body {...props} />} />
                  {/* <Route
                  path="/white-board-ui"
                  exact
                  element={<Body {...props} />}
                /> */}
                  {/* <Route
                    path={WHITE_BOARD_PATH + "/:documentID"}
                    element={<ShareBoard {...props} />}
                  />
                  <Route
                    path={QR_CODE_PATH}
                    element={<QRCodeGenrator {...props} />}
                  />
                  <Route path={SLIDE_TEXT} element={<SlideText {...props} />} />
                  <Route
                    path={COUNT_DOWN_PATH + "/:documentID"}
                    element={<CountDownComponent {...props} />}
                  />
                  <Route
                    path={IDEAS_LIST_PATH}
                    element={<IdeasList {...props} />}
                  /> */}
                  {gridItemsList.map((item) => {
                    return (
                      <Route
                        key={item.path}
                        path={item.path}
                        element={item.element}
                      />
                    );
                  })}
                  <Route component={ErrorPage} />
                </Routes>
                {/* <Footer /> */}
              </div>
            </>
          </Router>
        </IntlProvider>
      </div>
    );
  }
}

// function App(props) {
//   return (
//     <div className="App">
//       <IntlProvider locale="en">
//         <Router>
//           <>
//             <Header />
//             {/* <Body {...props}  /> */}
//             <Routes>
//               <Route
//                 path="/white-board-ui"
//                 exact
//                 element={<Body {...props} />}
//               />
//               <Route
//                 path="/white-board-ui/:documentID"
//                 element={<ShareBoard {...props} />}
//               />
//               <Route component={ErrorPage} />
//             </Routes>
//             <Footer />
//           </>
//         </Router>
//       </IntlProvider>
//     </div>
//   );
// }

export default App;
