import React, { useState } from "react";
import { MDBInput } from "mdbreact";
import CodeEditor from "@uiw/react-textarea-code-editor";

const TextArea = (props) => {
  const { value, setValue, language } = props;
  //   const { bgcolor, setBGColor } = useState("#4c6b6b");
  return (
    <div className="code-edit">
      {/* <div>
        <input
          type="color"
          id="Background"
          name="Background"
          value={bgcolor}
          onChange={(e) => console.log(e.target.value)}
        />
        <label>Background</label>
      </div> */}
      <CodeEditor
        value={value}
        language={language}
        placeholder={"Please enter " + language + " code."}
        onChange={(evn) => setValue(evn.target.value)}
        padding={15}
        minHeight="600px"
        style={{
          fontSize: 12,
          backgroundColor: "#f5f5f5",
          fontFamily:
            "ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace",
        }}
      />
    </div>
  );
};

export default TextArea;
